import { useCallback, useMemo } from 'react'
import { useQuery, useMutation } from 'apollo-client'
import { GraphQLError, ServerError, UserError } from 'helpers'

import giftSubscriptionQuery from './graph/giftSubscription.graphql'

import giftSubscriptionRedeemQuery from './graph/giftSubscriptionRedeem.graphql'
import type { GiftSubscriptionRedeemVariables } from './graph/giftSubscriptionRedeem.graphql'

import purchasedGiftSubscriptionsQuery from './graph/purchasedGiftSubscriptions.graphql'

import giftSubscriptionResendEmailQuery from './graph/giftSubscriptionResendEmail.graphql'
import type { GiftSubscriptionResendEmailVariables } from './graph/giftSubscriptionResendEmail.graphql'


type useGiftSubscriptionProps = {
  ignoreChanges?: boolean // ignore changes from the cache
}

export const useGiftSubscription = (props: useGiftSubscriptionProps = {}) => {
  const { ignoreChanges } = props

  const { data, isFetching } = useQuery(giftSubscriptionQuery, {
    nextFetchPolicy: ignoreChanges ? 'standby' : 'cache-first',
  })

  return {
    giftSubscription: data?.giftSubscription?.data,
    error: data?.giftSubscription?.error,
    isFetching,
  }
}


export const useGiftSubscriptionRedeem = () => {
  const [ mutate ] = useMutation(giftSubscriptionRedeemQuery)

  return useCallback(async (input: GiftSubscriptionRedeemVariables['input']) => {
    const result = await mutate({
      variables: {
        input,
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.giftSubscriptionRedeem

    if (error) {
      throw new UserError(error)
    }

    return data
  }, [ mutate ])
}


export const usePurchasedGiftSubscriptions = () => {
  const { data, isFetching } = useQuery(purchasedGiftSubscriptionsQuery)

  const giftSubscriptions = data?.currentUser?.data?.giftSubscriptions

  const unactivatedGiftSubscriptions = useMemo(() => {
    return giftSubscriptions?.filter((item) => item.canResendEmail)
  }, [ giftSubscriptions ])

  return {
    giftSubscriptions,
    unactivatedGiftSubscriptions,
    isFetching,
  }
}

export const useGiftSubscriptionResendEmail = () => {
  const [ mutate, { isFetching } ] = useMutation(giftSubscriptionResendEmailQuery)

  const submit = useCallback(async (input: GiftSubscriptionResendEmailVariables['input']) => {
    const result = await mutate({
      variables: {
        input,
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const errors = result.data.giftSubscriptionResendEmail
      .map(({ error }) => error)
      .filter(Boolean)

    if (errors.length) {
      throw new ServerError(errors, errors[0].message)
    }

    return
  }, [ mutate ])

  return [ submit, { isFetching } ] as const
}
